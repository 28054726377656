/* CSS para o container dos cards */
.card-container {
  display: flex;
  gap: 16px; /* Espaçamento entre os cards */
  justify-content: flex-start; /* Alinha os cards à esquerda */
  overflow-x: auto; /* Adiciona scroll horizontal */
  padding: 16px; /* Adiciona espaçamento interno */
  box-sizing: border-box; /* Inclui padding e border no tamanho total do elemento */
}

/* CSS para os blocos de texto (cards) */
.text-block {
  flex: 0 0 auto; /* Faz com que os cards não encolham e tenham uma largura fixa */
  width: 290px; /* Define uma largura máxima para os cards */
  height: 400px; /* Altura fixa dos cards */
  border: 1px solid #ccc; /* Borda do card */
  border-radius: 8px; /* Arredonda os cantos dos cards */
  padding: 16px; /* Espaçamento interno */
  box-shadow: 10px 3px 10px #ccc; /* Sombra para dar um efeito de elevação */
  opacity: 0; /* Inicialmente invisível */
  transform: translateY(20px); /* Inicialmente deslocado para baixo */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Adiciona transições */
  background-color: #fff; /* Cor de fundo dos cards */
  display: flex; /* Adiciona flexbox para centralizar o conteúdo */
  flex-direction: column; /* Organiza o conteúdo verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  text-align: center; /* Centraliza o texto dentro dos cards */
}

/* Classe para cards visíveis */
.text-block.fade-in {
  opacity: 1; /* Torna o card visível */
  transform: translateY(0); /* Move o card para a posição inicial */
}

.text-block:hover {
  transform: scale(1.05); /* Aumenta o card ao passar o mouse */
  background-color: #f9f9f9; /* Cor de fundo ao passar o mouse */
}

/* Estilos para o título */
.text-block h1 {
  margin-bottom: 10px;
  font-size: 1.5em; /* Tamanho da fonte do título */
  /* white-space: nowrap; Impede que o título quebre a linha */
  /* overflow: hidden; Esconde o texto excedente */
  text-overflow: ellipsis; /* Adiciona reticências ao texto excedente */
}

/* Estilos para parágrafos */
.text-block p {
  margin: 0;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.6); /* Cor do texto */
}

/* Estilos específicos para o texto dentro dos blocos */
.piso-name {
  margin: 0;
  font-size: 1.5em; /* Tamanho da fonte do nome do piso */
  height: 30px;
}

.garantia {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.garantia-info {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 5px; /* Espaçamento entre número e meses */
  background-color: #f0f0f0; /* Fundo para destacar a garantia */
  padding: 10px;
  border-radius: 5px;
}

.garantia-numero {
  font-size: 3em;
  margin: 0;
}

.garantia-meses {
  font-size: 1em;
}

.condicoes-especiais {
  margin-top: 20px;
  font-weight: bold;
}

.descricao-opaca {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6); /* Texto com opacidade reduzida */
  text-align: center; /* Centraliza o texto dentro do card */
  overflow-wrap: break-word; /* Quebra palavras longas se necessário */
  word-wrap: break-word; /* Para compatibilidade com navegadores mais antigos */
}

/* Estilos para o hr */
.text-block hr {
  width: 80%; /* Largura da linha de separação */
  border: none; /* Remove qualquer borda padrão */
  border-top: 1px solid #ccc; /* Define uma linha superior */
  margin: 20px 0; /* Espaçamento acima e abaixo da linha */
}
