/* DetalhesProtocolo.css */
.detalhes-protocolo-container {
  margin: 10px auto;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

@media (min-width: 768px) {
  .detais-protocolo-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .detais-protocolo-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.detalhes-protocolo-container h1 {
  font-size: 28px;
  color: #222;
  margin-bottom: 20px;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.info-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  border-left: 3px solid #444; /* Linha de destaque em cor neutra */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.info-card-status {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.info-card h2 {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.info-card p {
  font-size: 14px;
  color: #666;
}

.info-section .info-section-status {
  margin-top: 30px;
}
.info-section-status {
  background-color: #ffffff;
  border-left: 6px solid green; /* Linha de destaque em cor neutra */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 5px;
}
.info-section .info-card {
  background-color: #ffffff;
  border-left: 6px solid #444; /* Linha de destaque em cor neutra */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-section .info-section-status h2 {
  font-size: 18px;
  color: #333;
}

.info-section .info-section-status ul {
  list-style: none;
  padding: 0;
}
.info-card-status {
  content: none; /* Remove o marcador se aplicado */
}

.info-section .info-section-status ul li {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

/* section mobile */
.info-section-mobile {
  margin-bottom: 30px;
}

.back-button {
  background-color: #444; /* Cor neutra para o botão */
  color: #fff;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  display: block;
  width: 220px;
  text-align: center;
  margin: 30px auto;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #333; /* Cor mais escura no hover */
}
.info-grid-historico {
  display: grid;
  grid-template-columns: 80% 20%; /* 80% para o histórico de atualizações, 20% para o status */
  gap: 20px; /* Espaçamento entre os itens */
  margin-bottom: 20px; /* Margem inferior para separar do restante da página */
}

.info-card li::marker {
  content: none; /* Remove o marcador se aplicado */
}

.info-card-status li::marker {
  content: none; /* Remove o marcador se aplicado */
}

.mensagem-cliente {
  margin-top: 20px;
  margin-bottom: 60px;
  padding: 15px;
  background-color: #f9f9f9;
  position: relative;
}

.mensagem-cliente h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.mensagem-cliente textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
  transition: border-color 0.3s ease;
}

.mensagem-cliente textarea:focus {
  outline: none;
  border-color: #999;
}

.mensagem-cliente textarea.erro {
  border-color: red;
}

.handle-send {
  /* position: absolute; */
  width: 230px;
  float: right;
  bottom: -20px;
  right: 15px;
  padding: 10px 15px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.handle-send:hover {
  background-color: #166219;
}

.handle-send-mobile {
  width: 100%;
  bottom: -20px;
  right: 15px;
  padding: 10px 15px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.loading-spinner-detalhes-resposta {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 230px;
}

.loading-spinner-detalhes-resposta-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
}

.loading-spinner-detalhes-resposta-mobile::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-top-color: #85a1b3; /* Cor da parte visível da bolinha */
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

.loading-spinner-detalhes-resposta::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-top-color: #85a1b3; /* Cor da parte visível da bolinha */
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

.arquivos-enviados {
  margin-top: 20px;
}

.arquivo-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
}

.nome-arquivo {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.arquivo-visualizar {
  display: flex;
  align-items: center;
}

.visualizar-label {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.url-arquivo {
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  word-break: break-all; /* Garante que URLs longas não quebrarão o layout */
}

.image-container {
  display: inline-block;
  margin: 10px; /* Espaçamento entre as imagens */
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 230px; /* Largura do container */
  height: 230px; /* Altura do container para incluir o texto */
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
}

.image-container img {
  max-width: 100%;
  max-height: 100%; /* Altura da imagem */
  object-fit: contain;
}

.image-container .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px; /* Altura para ícones */
  width: 100%;
}

.image-container p {
  margin-top: 5px;
  font-size: 1em;
  word-wrap: break-word;
  max-width: 100%;
  height: 40px; /* Espaço reservado para o texto */
  overflow: hidden;
  text-overflow: ellipsis; /* Adiciona reticências caso o texto seja muito longo */
}

.info-section .info-card ul li {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.info-section-mobile .info-card ul li {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
