.spacer {
  width: 100%;
  height: 140px;
  background-color: black;
}

.container-itens a {
  text-decoration: none;
  color: inherit;
}

.logo {
  width: 90%;
  height: 90%;
}

.container-logo {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 75px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white; /* Ajuste a cor conforme necessário */
  z-index: 9999; /* Certifique-se de que o z-index é suficientemente alto para estar acima de outros elementos */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra para destacar a barra de navegação */
}

.container-itens {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  gap: 20px;
  cursor: pointer;
}

.container-itens p {
  margin: 0;
  position: relative;
  transition: border-bottom 0.3s ease;
  display: flex;
  align-items: center;
}

.container-itens p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 100%;
  height: 1px;
  background-color: #db896c;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
  z-index: 99;
}

.container-itens p:hover::after {
  transform: scaleX(1);
}

.arrow-icon {
  margin-top: 1px; /* Ajuste conforme necessário */
  margin-left: 3px; /* Ajuste conforme necessário */
  display: flex;
  align-items: center;
}

.container-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white; /* Ajuste a cor conforme necessário */
  z-index: 9999; /* Certifique-se de que o z-index é suficientemente alto para estar acima de outros elementos */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra para destacar a barra de navegação */
}

.icone {
  width: 2300px;
}

.menu-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -350px; /* Inicialmente fora da tela */
  width: 350px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  transition: right 0.4s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  padding: 100px 0px;
}

.sidebar.open {
  right: 0; /* Move a sidebar para dentro da tela */
}

.sidebar-content {
  padding: 30px;
  /* Estilos adicionais para o conteúdo do menu */
}

.sidebar-content a {
  display: flex;
  flex: 1;
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  justify-content: space-between;
}

.sidebar-content a:focus {
  color: #ea845b;
}

.sidebar-content .sidebar-item {
  display: flex;
  margin-bottom: 15px;
}

.menu-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  padding: 10px 20px;
  font-size: 16px;
}

.wrapper {
  width: 50px;
  /* position: absolute; */
  top: 50%¨;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper:after,
.wrapper:before,
.wrapper div {
  background-color: #fff;
  border-radius: 3px;
  content: '';
  display: block;
  height: 5px;
  margin: 7px 0;
  transition: .2s;
}


/* Animação dos ícones */
.icon-enter {
  opacity: 0;
  transform: rotate(0deg);
}

.icon-enter-active {
  opacity: 1;
  transform: rotate(180deg);
  transition: opacity 300ms, transform 300ms;
}

.icon-exit {
  opacity: 1;
  transform: rotate(-180deg);
}

.icon-exit-active {
  opacity: 1;
  transform: rotate(-180deg);
}



.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 22px;
  height: 18px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  transform: rotate(0);
  transition: transform 0.3s ease;
}

.hamburger-menu.open {
  transform: rotate(45deg);
}

.hamburger-menu span {
  width: 100%;
  height: 2px;
  background-color: #333;
  border-radius: 2px;
  transition: all 0.1s ease;
  position: relative;
}

.hamburger-menu span:nth-child(1) {
  /* transform-origin: top left; */
}

.hamburger-menu span:nth-child(2) {
  opacity: 1;
  transform-origin: center;
}

.hamburger-menu span:nth-child(3) {
  transform-origin: bottom left;
}

.hamburger-menu.open span:nth-child(1) {
  transform: translateY(7px) rotate(90deg);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: translateY(-5px) rotate(0deg);
}