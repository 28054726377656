.product-table {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.product-table th,
.product-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.product-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.product-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.product-table tr:hover {
  background-color: #f1f1f1;
}

.assistencia-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.assistencia-button:hover {
  background-color: #45a049;
}

.alert-container {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ff9800;
}

.alert-icon {
  font-size: 1.2em;
}

.alert-text {
  font-size: 0.9em;
  color: #ff9800;
}
