/* Container principal com imagem e sobreposição de texto */
.container-infos {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.container-infos img.contract {
  width: 100%;
  height: 65vh;
  object-fit: cover;
  z-index: -1;
}

.card-container {
  display: flex;
  /* flex-wrap: nowrap; Impede que os cards quebrem para a próxima linha */
  gap: 16px; /* Espaçamento entre os cards */
  justify-content: flex-start; /* Alinha os cards à esquerda */
  overflow-x: auto; /* Adiciona scroll horizontal */
  overflow-y: hidden;
  padding: 16px; /* Adiciona espaçamento interno */
  box-sizing: border-box; /* Inclui padding e border no tamanho total do elemento */
  /* white-space: nowrap; Evita quebra de linha dentro do container */
}
.container-infos .text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  box-sizing: border-box;
}

.container-infos h1,
.container-infos p {
  margin: 0;
  z-index: 1;
}

.custom-dot-list-style {
  text-align: center;
  padding-top: 10px;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-item.fade {
  opacity: 1;
}

/* CSS para botões de marca */
.container-marcas {
  margin: 15px;
  margin-top: -180px;
  display: flex;
  width: 100%;
  box-shadow: 10px 3px 10px #ccc;
  height: 60px;
}

.container-marcas button {
  flex: 1;
  background-color: #f5f5f5;
  border: none;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.container-marcas button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: #db896c;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.container-marcas button:hover::after,
.container-marcas button:focus::after {
  transform: scaleX(1);
}

.container-marcas button:hover,
.container-marcas button:focus {
  background-color: whitesmoke;
  outline: none;
}

/* CSS para a área "Saiba mais sobre políticas" */
.container-know-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.title-know-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.text-know-more {
  padding-left: 60px;
  padding-right: 60px;
}

.subtitle-know-more {
  margin-bottom: 15px;
}

.container-know-more .quadro-container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.container-know-more .quadro-container > div {
  flex: 1;
  border: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 7px;
}
.container-sol-asdsitencia {
  padding: 20px;
  /* max-width: 800px; */
  margin: 0 auto;
  text-align: center;
  margin-left: 25px;
}

.container-title h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.container-sol-asdsitencia-2 {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}

.container-text-sol {
  flex: 0 0 50%; /* O quadro ocupará 50% da largura do contêiner pai */
  margin-right: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: left;
}

.container-text-sol h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.container-text-sol p {
  font-size: 16px;
  line-height: 1.5;
}

.container-button {
  margin-top: 20px;
  text-align: left;
}

.container-button button {
  background-color: #1f1e1d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.container-button button:hover {
  background-color: #343634;
}

.container-footer {
  width: 100%;
  height: 350px;
}

.container-form-modal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-cards-container {
  width: 100%;
  overflow-x: auto;
  cursor: grab;
  display: flex;
}

.info-cards-container:active {
  cursor: grabbing;
  user-select: none;
}

.info-cards-container > * {
  flex-shrink: 0;
}

.container-infos-mobile {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
  margin-left: -5px;
}

.container-infos-mobile img.contract {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  z-index: -1;
}

.container-marcas-mobile {
  margin-top: -180px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  box-shadow: 10px 3px 10px #ccc;
  height: 60px;
  margin-bottom: 20px;
}

.icon-menu-mobile {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100px;
  justify-content: center;
  align-items: center;
}

.container-marcas-mobile button {
  flex: 1;
  background-color: #ffff;
  border: none;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s;
  /* font-weight: 600; */
  flex-direction: row;
  width: 100%;
  padding: 20px;
  justify-content: center;
}

.container-marcas-mobile button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: #db896c;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.container-marcas-mobile button:hover::after,
.container-marcas-mobile button:focus::after {
  transform: scaleX(1);
}

.dropdown {
  /* position: absolute; */
  top: 0; /* Ajuste conforme necessário */
  /* left: 0; */
  background-color: #ffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* z-index: 1; */
  margin-bottom: 20px;
}

.container-marcas-mobile-li {
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  height: 60px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* border: 1px solid; */
}
.container-marcas-mobile-li button {
  flex: 1;
  background-color: #ffff;
  border: none;
  position: relative;
  display: flex;
  cursor: pointer;
  /* transition: background-color 0.3s; */
  /* font-weight: 600; */
  flex-direction: row;
  width: 100%;
  /* padding: 20px; */
  justify-content: center;
  /* border-bottom: 1px solid #cccccc; */
}

.container-know-more-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -5px;
}

.title-know-more-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}

.text-know-more-mobile {
}

.subtitle-know-more-mobile {
  margin-bottom: 15px;
}

.container-know-more-mobile .quadro-container {
  margin-top: 30px;
  margin-bottom: 30px;
  /* display: flex; */
  justify-content: space-between;
  gap: 20px;
}

.container-know-more-mobile .quadro-container > div {
  flex: 1;
  border: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 7px;
  margin-top: 30px;
}

.container-sol-asdsitencia-mobile {
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  margin-left: -10px;
}

.container-text-sol-mobile {
  flex: 1; /* O quadro ocupará 50% da largura do contêiner pai */
  border: 1px solid #ccc;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: left;
}

.container-text-sol h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.container-text-sol p {
  font-size: 16px;
  line-height: 1.5;
}

.dropdown-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 12px;
  height: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 340px;
  padding: 0;
  box-sizing: border-box;
  transform: rotate(0);
  position: absolute;
  transition: transform 0.3s ease;
}

.dropdown-icon span {
  width: 100%;
  height: 2px;
  background-color: #333;
  border-radius: 2px;
  transition: all 0.3s ease;
  position: relative;
}

.dropdown-icon span:nth-child(1) {
  transform-origin: top left;
  transform: translateY(2px) translateX(-3px) rotate(45deg);
}

.dropdown-icon span:nth-child(2) {
  transform-origin: top left;
  transform: translateY(5px) translateX(3px) rotate(-45deg);
}

.dropdown-icon.open span:nth-child(1) {
  transform-origin: top left;
  transform: translateY(2px) translateX(2px) rotate(135deg);
}

.dropdown-icon.open span:nth-child(2) {
  transform-origin: top left;
  transform: translateY(5px) translateX(8px) rotate(-135deg);
}
