.custom-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7); /* Cor inativa */
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.custom-dot.active {
  background-color: rgba(0, 0, 0, 0.5); /* Cor ativa */
}
