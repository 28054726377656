body {
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.greeting-container {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.greeting-container h1:first-of-type {
  margin: 0;
  font-size: 2.5em;
  color: #333;
}

.greeting-container h2 {
  margin: 10px 0 0;
  font-size: 1.5em;
  color: #666;
}

.greeting-container h1:last-of-type {
  margin: 10px 0 0;
  font-size: 2em;
  color: #333;
}
/* Barra de botões minimalista */
.button-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  padding-right: 20px;
}

.toolbar-button {
  margin-left: 10px;
  background-color: transparent;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.toolbar-button:hover {
  background-color: #eaeaea;
}

.minimal-button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.assistencia-container {
  padding: 20px;
  display: flex;
  justify-content: center;
}

/* Estilo para dispositivos móveis */
.assistencia-container-mobile {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.assistencia-container {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.button-logout {
  all: unset;
  display: flex;
  cursor: pointer;
  padding: 0px;
  align-items: center;
}

.button-logout:hover {
  color: #5ebb8d;
}

.assistencia-container-mobile {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  /* justify-content: center; */
}

.card-container-mobile {
  display: flex;
  padding: 15px 0px;
  flex-direction: column;
  width: 100%;
  padding: -20px;
  /* position: relative; */
  border-bottom: 2px solid #c6c6c7;
  margin-top: 10px;
}

.info-card-mobile {
  padding: 0;
  margin: 0;
}

.button-card {
  all: unset;
  width: 200px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: -10px;
}

.card-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 12px;
  height: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 300px;
  padding: 0;
  box-sizing: border-box;
  transform: rotate(0);
  position: absolute;
  transition: transform 0.3s ease;
}

.card-icon span {
  width: 100%;
  height: 2px;
  background-color: #5ebb8d;
  border-radius: 2px;
  transition: all 0.3s ease;
  position: relative;
}

.card-icon span:nth-child(1) {
  transform-origin: top left;
  transform: translateY(2px) translateX(-3px) rotate(45deg);
}

.card-icon span:nth-child(2) {
  transform-origin: top left;
  transform: translateY(5px) translateX(3px) rotate(-45deg);
}

.card-icon.open span:nth-child(1) {
  transform-origin: top left;
  transform: translateY(2px) translateX(2px) rotate(135deg);
}

.card-icon.open span:nth-child(2) {
  transform-origin: top left;
  transform: translateY(5px) translateX(8px) rotate(-135deg);
}
