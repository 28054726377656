.container-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 75px;
  background-color: #363636; /* Ajuste a cor de fundo conforme necessário */
  color: #fff;
  letter-spacing: 0.06em; /* Adiciona espaçamento entre as letras */
  padding-top: 50px;
}

.footer-column {
  flex: 1;
  margin: 0 20px;
}

.footer-column a {
  color: inherit; /* Para herdar a cor do elemento pai */
  text-decoration: none; /* Remove o sublinhado */
}
.footer-column-mobile a {
  color: inherit; /* Para herdar a cor do elemento pai */
  text-decoration: none; /* Remove o sublinhado */
}

.footer-column h3 {
  font-size: 17px;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style-type: disc; /* Adiciona os pontinhos */
  padding-left: 20px; /*Espaço entre os pontos e o texto */
}

.footer-column ul li {
  margin-bottom: 5px;
  cursor: pointer; /* Muda o cursor para pointer */
  transition: color 0.3s ease; /* Transição suave para a cor */
}

.footer-column ul li:hover {
  color: #ed8457; /* Muda a cor para laranja ao passar o mouse */
}

.footer-column a:hover {
  color: #ed8457; /* Muda a cor para laranja ao passar o mouse */
}

.footer-column p {
  margin: 5px 0;
}

.container-footer-mobile {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
  /* padding: 20px 75px; */
  background-color: #363636; /* Ajuste a cor de fundo conforme necessário */
  color: #fff;
  letter-spacing: 0.06em; /* Adiciona espaçamento entre as letras */
  padding-top: 50px;
  flex-wrap: wrap;
}

.footer-column-mobile {
  display: flex;
  width: 100%;
  padding-right: 80px;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  margin-bottom: 80px;
  line-height: 20px;
}

.footer-column-mobile h3 {
  font-size: 17px;
  margin-bottom: 10px;
}

.footer-column-mobile ul {
  list-style-type: disc; /* Adiciona os pontinhos */
  padding-left: 20px; /*Espaço entre os pontos e o texto */
}

.footer-column-mobile ul li {
  margin-bottom: 5px;
  cursor: pointer; /* Muda o cursor para pointer */
  transition: color 0.3s ease; /* Transição suave para a cor */
}

.footer-column-mobile ul li:hover {
  color: #ed8457; /* Muda a cor para laranja ao passar o mouse */
}

.footer-column-mobile p {
  margin: 5px 0;
}
