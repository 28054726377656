.acompanhamento-container {
  max-width: 1200px;
  min-height: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.acompanhamento-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.acompanhamento-table {
  width: 100%;
  border-collapse: collapse;
}

.acompanhamento-table th,
.acompanhamento-table td {
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.acompanhamento-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.acompanhamento-table td {
  color: #555;
  text-align: center;
  /* display: flex; */
  cursor: pointer;
}

.acompanhamento-table tr:hover {
  background-color: #f9f9f9;
}

.status-label {
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
}

.status-aguardando-analise {
  background-color: #6c757d;
}

.status-aguardando-tecnico {
  background-color: #383845;
}
.status-em-analise {
  background-color: #20c997;
}

.status-aguardando-cliente {
  background-color: #fd7e14;
}

.status-procedente {
  background-color: #28a745;
}

.status-improcedente {
  background-color: #dc3545;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.loading-spinner::after {
  content: "";
  width: 40px;
  height: 40px;
  border: 6px solid #ccc;
  border-top-color: #85a1b3; /* Cor da parte visível da bolinha */
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner-detalhes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
}

.loading-spinner-detalhes::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-top-color: #85a1b3; /* Cor da parte visível da bolinha */
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

.loading-spinner-detalhes-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-detalhes-mobile::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-top-color: #85a1b3; /* Cor da parte visível da bolinha */
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

.acompanhamento-container-mobile {
  width: 100%;
}

.acompanhamento-container-mobile h1 {
  text-align: center;
  margin: 0 auto; /* Remove margens indesejadas e centraliza */
  padding: 10px 0; /* Adiciona um pouco de padding para evitar que o texto encoste nas bordas */
}
.acompanhamento-mobile-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.info-card-mobile-acompanhamento {
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box; /* Garante que o padding seja considerado no cálculo da largura */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.assistencia-button {
  width: 100%;
}
