/* ModalLoading.css */
.container-modal-loadin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container-gif {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  /* transition: opacity 0.5s ease; */
  height: 150px;
}

.fade-in {
  animation: fadeInEffect 0.8s ease forwards;
}

.fade-out {
  animation: fadeOutEffect 0.8s ease forwards; /* Duração de 0.8s para um efeito mais gradual */
}

.success-message {
  font-size: 16px;
  color: #4caf50; /* Verde de sucesso */
  margin-top: 10px;
  text-align: center;
}

.gif {
  width: 120px;
  height: 120px;
}

.gif-checked {
  width: 90px;
  height: 90px;
}

.phrases {
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.phrase {
  display: inline-block;
  animation: slideInOut 2s ease forwards;
  color: black;
}

.success-message {
  font-size: 1.2em;
  color: green;
  margin-top: 10px;
}

@keyframes slideInOut {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes fadeOutEffect {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInEffect {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
}
