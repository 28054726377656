/* Estilo para o conteúdo principal */
.protocolo-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  text-align: center;
}

.protocolo-container div {
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-size: 1.75rem;
  color: #333;
  max-width: 500px;
  width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.protocolo-container div:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.protocolo-container div:before {
  content: "";
  font-size: 3rem;
  display: block;
  margin-bottom: 20px;
  animation: pop 0.5s ease-out;
}

.acompanhamento-button {
  background-color: #5ebb8d;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.acompanhamento-button:hover {
  background-color: #4aa77e;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
